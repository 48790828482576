/*
  销售业绩统计
*/

<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <label>分公司：</label>
        <a-select placeholder="请选择区域/分公司" v-model="insetData.filialeType" style="width: 200px" allowClear @change="filialeChange" @focus="filialeFocus">
          <a-select-option v-for="item in companyArr" :key="item">{{ item }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>小组：</label>
        <a-select placeholder="请选择小组" v-model="insetData.counselorType" style="width: 200px" allowClear @change="counselorChange">
          <a-select-option v-for="item in counselorArr" :key="item.sysId">{{ item.nickName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>顾问：</label>
        <a-select placeholder="请选择顾问" v-model="insetData.counselorType" style="width: 200px" allowClear @change="counselorChange">
          <a-select-option v-for="item in counselorArr" :key="item.sysId">{{ item.nickName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="nowMonthClick">本月</a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="prevMonthClick">上月</a-button>
      </a-form-item>
      <a-form-item>
        <label>自定义时间段：</label>
        <a-range-picker v-model="insetData.registerTime" :disabledDate="disabledDate" :defaultValue="[moment(startDate, dateFormat), moment(endDate, dateFormat)]" @change="createChange" size="small" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" icon="search" @click="inquireClick('1')">查询</a-button>
      </a-form-item>
    </a-form>
    <a-tabs defaultActiveKey="1" @change="tabChange">
      <a-tab-pane tab="按区域" key="1">
        <a-spin tip="加载中..." :spinning="loadingData">
          <a-table :columns="columnsa" size="middle" :dataSource="tableData" :pagination="false" :scroll="{ x: 3000 }" :bordered="true" rowKey="groupId" />
        </a-spin>
        <!-- 分页部分 -->
        <div class="pageClass">
          <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
        </div>
      </a-tab-pane>
      <a-tab-pane tab="按部门" key="2">
        <a-spin tip="加载中..." :spinning="loadingData">
          <a-table :columns="columnsb" :dataSource="tableData" :pagination="false" :scroll="{ x: 3000 }" :bordered="true" rowKey="groupId" />
        </a-spin>
        <!-- 分页部分 -->
        <div class="pageClass">
          <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
        </div>
      </a-tab-pane>
      <a-tab-pane tab="按顾问" key="3">
        <a-spin tip="加载中..." :spinning="loadingData">
          <a-table :columns="columnsc" :dataSource="tableData" :pagination="false" :scroll="{ x: 3000 }" :bordered="true" rowKey="groupId" />
        </a-spin>
        <!-- 分页部分 -->
        <div class="pageClass">
          <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
        </div>
      </a-tab-pane>
    </a-tabs>
    <!-- 表格部分 -->
  </div>
</template>

<script>
import moment from 'moment'
import { getCompany, getGroup, getCounselor, getSalesDataOdd } from '../../server/salesList'
import { mapGetters } from 'vuex'

export default {
  name: 'SalesList',
  data() {
    return {
      // 默认搜索框model数据
      insetData: {
        filialeType: undefined,
        groupeType: undefined,
        counselorType: undefined,
        registerTime: undefined
      },
      startDate: undefined, // 月初
      endDate: undefined, // 当前日期
      companyArr: [], // 区域分公司数组
      groupArr: [], // 组别数组
      counselorArr: [], // 顾问数组
      tableObject: { // 搜索传参数据
        page: 1,
        rows: 10,
        condition: {
          sysId: '',
          type: 1
        }
      },
      // 区域表头
      columnsa: [
        {
          title: '部门',
          dataIndex: 'branchName',
          key: 'branchName',
          width: 300,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '提点服务费',
          dataIndex: 'tdserveexp',
          key: 'tdserveexp',
          align: 'center',
          ellipsis: true
        },
        {
          title: '服务费',
          dataIndex: 'realserveexp',
          key: 'realserveexp',
          align: 'center',
          ellipsis: true
        },
        {
          title: '退款服务费',
          dataIndex: 'refundserveexp',
          key: 'refundserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '提成服务费',
          dataIndex: 'jtserveexp',
          key: 'jtserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '风险代理服务费',
          dataIndex: 'fxserveexp',
          key: 'fxserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '风险代理退款服务费',
          dataIndex: 'refxserveexp',
          key: 'refxserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '商标注册件数',
          dataIndex: 'trnum',
          key: 'trnum',
          align: 'center',
          ellipsis: false
        },
        {
          title: '退款商标注册件数',
          dataIndex: 'retrnum',
          key: 'retrnum',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利服务费',
          dataIndex: 'ptserveexp',
          key: 'ptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利退款服务费',
          dataIndex: 'reptserveexp',
          key: 'reptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利提点服务费',
          dataIndex: 'jtptserveexp',
          key: 'jtptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利提点服务费占比',
          dataIndex: 'jtptserveexpzb',
          key: 'jtptserveexpzb',
          align: 'center',
          ellipsis: false
        }
      ],
      // 组别表头
      columnsb: [
        {
          title: '部门',
          dataIndex: 'branchName',
          key: 'branchName',
          width: 300,
          fixed: 'left',
          align: 'center'
        },
        {
          title: ' 组别',
          dataIndex: 'groupName',
          key: 'groupName',
          width: 300,
          align: 'center'
        },
        {
          title: '提点服务费',
          dataIndex: 'tdserveexp',
          key: 'tdserveexp',
          align: 'center',
          ellipsis: true
        },
        {
          title: '服务费',
          dataIndex: 'realserveexp',
          key: 'realserveexp',
          align: 'center',
          ellipsis: true
        },
        {
          title: '退款服务费',
          dataIndex: 'refundserveexp',
          key: 'refundserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '提成服务费',
          dataIndex: 'jtserveexp',
          key: 'jtserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '风险代理服务费',
          dataIndex: 'fxserveexp',
          key: 'fxserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '风险代理退款服务费',
          dataIndex: 'refxserveexp',
          key: 'refxserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '商标注册件数',
          dataIndex: 'trnum',
          key: 'trnum',
          align: 'center',
          ellipsis: false
        },
        {
          title: '退款商标注册件数',
          dataIndex: 'retrnum',
          key: 'retrnum',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利服务费',
          dataIndex: 'ptserveexp',
          key: 'ptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利退款服务费',
          dataIndex: 'reptserveexp',
          key: 'reptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利提点服务费',
          dataIndex: 'jtptserveexp',
          key: 'jtptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利提点服务费占比',
          dataIndex: 'jtptserveexpzb',
          key: 'jtptserveexpzb',
          align: 'center',
          ellipsis: false
        }
      ],
      // 顾问表头
      columnsc: [
        {
          title: '部门',
          dataIndex: 'branchName',
          key: 'branchName',
          width: 200,
          fixed: 'left',
          align: 'center'
        },
        {
          title: ' 组别',
          dataIndex: 'groupName',
          key: 'groupName',
          width: 200,
          align: 'center'
        },
        {
          title: ' 顾问',
          dataIndex: 'gwNickname',
          key: 'gwNickname',
          width: 200,
          align: 'center'
        },
        {
          title: '提点服务费',
          dataIndex: 'tdserveexp',
          key: 'tdserveexp',
          align: 'center',
          ellipsis: true
        },
        {
          title: '服务费',
          dataIndex: 'realserveexp',
          key: 'realserveexp',
          align: 'center',
          ellipsis: true
        },
        {
          title: '退款服务费',
          dataIndex: 'refundserveexp',
          key: 'refundserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '提成服务费',
          dataIndex: 'jtserveexp',
          key: 'jtserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '风险代理服务费',
          dataIndex: 'fxserveexp',
          key: 'fxserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '风险代理退款服务费',
          dataIndex: 'refxserveexp',
          key: 'refxserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '商标注册件数',
          dataIndex: 'trnum',
          key: 'trnum',
          align: 'center',
          ellipsis: false
        },
        {
          title: '退款商标注册件数',
          dataIndex: 'retrnum',
          key: 'retrnum',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利服务费',
          dataIndex: 'ptserveexp',
          key: 'ptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利退款服务费',
          dataIndex: 'reptserveexp',
          key: 'reptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利提点服务费',
          dataIndex: 'jtptserveexp',
          key: 'jtptserveexp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '专利提点服务费占比',
          dataIndex: 'jtptserveexpzb',
          key: 'jtptserveexpzb',
          align: 'center',
          ellipsis: false
        }
      ],
      tableData: [], // 表格数据
      tableDatas: [], // 临时为空
      pageTotal: 0, // 数据数量
      dateFormat: 'YYYY-MM-DD', // 日期显示格式
      loadingData: true // 是否加载
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.getNowFormatDate()
    this.getCurrentMonthFirst()
    this.tableObject.condition.sysId = this.userInfo.id
    this.tableObject.condition.tenantId = this.userInfo.tenantId
    this.tableObject.condition.updatetimeS = this.startDate
    this.tableObject.condition.updatetimeE = this.endDate
    if (this.tableObject.condition.sysId) {
      // 进入页面默认调一次表格接口
      this.getSalesDatas(this.tableObject)
    }
  },
  methods: {
    moment,
    // 获取当前月第一天
    getCurrentMonthFirst() {
      const date = new Date()
      date.setDate(1)
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      this.startDate = year + seperator1 + month + seperator1 + strDate
    },
    // 获取当前日期
    getNowFormatDate() {
      const date = new Date()
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      this.endDate = year + seperator1 + month + seperator1 + strDate
    },
    // 顾问区域分公司change事件
    filialeChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.company = val
        const datas = {
          sysId: this.userInfo.id,
          company: val
        }
        getGroup(datas).then(res => {
          if (res) {
            this.groupArr = res.data.groupList
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.groupArr = []
        this.counselorArr = []
        this.insetData.groupeType = undefined
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.company
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问区域分公司获得焦点事件
    filialeFocus() {
      const datas = {
        sysId: this.userInfo.id
      }
      getCompany(datas).then(res => {
        if (res) {
          this.companyArr = res.data.companyList
        }
      }).catch((err) => {
        console.log(err, '分公司')
      })
    },
    // 顾问组别change事件
    groupeChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.groupId = val
        const datas = {
          sysId: this.userInfo.id,
          groupId: val
        }
        getCounselor(datas).then(res => {
          if (res) {
            this.counselorArr = res.data.gwList
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.counselorArr = []
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问姓名change事件
    counselorChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.sysPramId = val // 赋值顾问id
      } else {
        delete this.tableObject.condition.sysPramId
      }
    },
    // 本月点击事件
    nowMonthClick(val) {
      this.tableObject.condition.updatetimeS = this.startDate
      this.tableObject.condition.updatetimeE = this.endDate
      this.getSalesDatas(this.tableObject)
    },
    // 上月点击事件
    prevMonthClick(val) {
      const nowdays = new Date()
      let year = nowdays.getFullYear()
      let month = nowdays.getMonth()
      if (month === 0) {
        month = 12
        year = year - 1
      }
      if (month < 10) {
        month = '0' + month
      }
      const myDate = new Date(year, month, 0)
      const startDate = year + '-' + month + '-01' // 上个月第一天
      const endDate = year + '-' + month + '-' + myDate.getDate() // 上个月最后一天
      this.tableObject.condition.updatetimeS = startDate
      this.tableObject.condition.updatetimeE = endDate
      this.getSalesDatas(this.tableObject)
    },
    // 不可选择的日期控制
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // 自定义时间change事件
    createChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.updatetimeS = dateString[0]
        this.tableObject.condition.updatetimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        this.tableObject.condition.updatetimeS = this.getCurrentMonthFirst
        this.tableObject.condition.updatetimeE = this.getNowFormatDate
      }
    },
    // 查询按钮时间
    inquireClick(val) {
      if (val === '1') {
        this.getSalesDatas(this.tableObject)
      }
    },
    // 表格数据
    getSalesDatas(val) {
      this.loadingData = true
      getSalesDataOdd(val).then(res => {
        if (res) {
          this.tableData = res.data.page.list
          this.pageTotal = res.data.page.total
        }
      }).then(() => {
        this.loadingData = false
      }).catch(err => {
        console.log(err, '表格数据')
      })
    },
    // tab栏change事件
    tabChange(key) {
      if (key) {
        this.tableData = []
        this.tableObject.condition.type = key
        this.getSalesDatas(this.tableObject)
      }
    },
    // 页码改变的回调
    pageChange(page, pageSize) {
      if (page && pageSize) {
        this.tableObject.page = page
        this.tableObject.rows = pageSize
        this.loadingData = true
        this.getSalesDatas(this.tableObject)
      }
    },
    // 显示数据量change事件
    pageSizePage(current, size) {
      if (current && size) {
        this.tableObject.page = current
        this.tableObject.rows = size
        this.loadingData = true
        this.getSalesDatas(this.tableObject)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .searchDiv {
    margin-bottom: 30px;
  }
  .searchDiv span {
    margin-right: 20px;
  }
  .pageClass {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    height: 80px;
  }
  .pageClass>.ant-pagination {
    position: absolute;
    right: 0;
    top: 10px;
  }
</style>
